/*
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-08-25 14:52:04
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-09-09 11:24:03
 * @FilePath: \dataview-next\src\manage-views\views\sysSetting\menu\components\icon.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const iconList = [
  'iconanquanmokuai',
  'iconjindumokuai',
  'iconhuiyimokuai',
  'iconjishuguanli1',
  'iconwuzimokuai',
  'iconzhishiku3',
  'icongongchengchangyongmokuai',
  'iconquanjingmokuai',
  'iconyunjijia1',
  'iconjishitongxun',
  'iconzhiliangmokuai',
  'iconshebei1',
  'iconshuziziliao'
]