<template>
  <div class="detail-main">
    <div class="main-title">
      <div class="main-label">分类详情</div>
      <div>
        <el-button
          v-if="judgingAPermission(['detail.store'])"
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="dialog = true"
          >新增</el-button
        >
      </div>
    </div>
    <el-table
      v-loading="loading"
      :data="detailData"
      class="detail-table"
      :header-cell-style="{ background: '#fafafa' }"
      row-key="id"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column label="分类名称">
        <template #default="{ row }">
          <!-- <i :class="['iconfont', row.icon]" class="icon-color"/> -->
          <span class="template-name">
            <svg aria-hidden="true" class="iconfont-svg-main">
              <use :xlink:href="'#' + row.icon" />
            </svg>
            <span> {{ row.name }} </span>
            
          </span>
        </template>
      </el-table-column>
      <el-table-column label="排序" width="200">
        <template #default="{ row }">
          <el-input v-model="row.sort" disabled></el-input>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200px">
        <template #default="{ row }">
          <el-button v-if="judgingAPermission(['detail.update'])" size="mini" type="text" @click="onEdit(row)"
            >修改</el-button
          >
          <el-button
            class="table-add-btn"
            v-if="row.type !== '3' && judgingAPermission(['detail.store'])"
            size="mini"
            type="text"
            @click="onAdd(row)"
            >添加子分类</el-button
          >
          <el-popconfirm
            v-if="judgingAPermission(['detail.delete'])"
            class="delete-btn"
            title="确定要删除这个分类吗？"
            @confirm="onDelete(row)"
          >
            <el-button size="mini" type="text" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 编辑抽屉 -->
    <EditDrawer
      :drawer="drawer"
      :editData="editData"
      @drawerClose="drawerClose"
      @drawerSave="drawerSave"
    >
    </EditDrawer>
    <!-- 新增弹窗 -->
    <AddDialog
      :dialog="dialog"
      :parentParam="parentData"
      @dialogClose="dialogClose"
      @dialogSave="dialogSave"
    >
    </AddDialog>
  </div>
</template>

<script>
import { Popconfirm } from 'element-ui'
import { getTemplate, detTemplate } from '@/api/saasManage'
import AddDialog from '@/manage-views/views/template/detail/components/AddDialog.vue'
import { judgingAPermission } from '@/utils/tools'
import EditDrawer from './EditDrawer.vue'
export default {
  components: {
    EditDrawer,
    AddDialog,
    'el-popconfirm': Popconfirm,
  },
  props: {},
  data() {
    return {
      judgingAPermission: judgingAPermission,
      loading: false,
      detailData: [],
      drawer: false,
      dialog: false,
      editData: {},
      parentData: {},
      search: '',
    }
  },
  methods: {
    /**
     * @desc:筛选
     */
    onSearch(data) {
      this.search = data
      this.getData()
    },
    onRefresh() {
      this.search = ''
      this.getData()
    },
    /**
     * @desc:关闭新增弹窗
     */
    dialogClose() {
      this.dialog = !this.dialog
    },
    dialogSave() {
      this.dialog = false
      this.getData()
    },
    drawerSave() {
      this.drawer = !this.drawer
      this.getData()
    },
    /**
     * @desc:关闭编辑抽屉
     */
    drawerClose() {
      this.drawer = !this.drawer
    },
    /**
     * @desc:打开编辑弹窗
     */
    onEdit(row) {
      this.editData = row
      this.drawer = !this.drawer
    },
    onAdd(row) {
      const { name, id } = row
      this.parentData = {
        parent_name: name,
        parent_id: id,
      }
      this.dialog = !this.dialog
    },
    onDelete(row) {
      detTemplate({ id: [row.id] })
        .then((res) => {
          if (res.data && res.data.code !== 200) {
            return false
          }
          this.getData()
        })
        .catch(() => {
          this.$message.error(`操作错误`)
        })
    },
    /**
     * @desc: 得到模板管理列表
     */
    getData() {
      this.loading = true
      let params = {
        name: this.search,
      }
      getTemplate(params)
        .then((res) => {
          if (res.data && res.data.code !== 200) {
            return false
          }
          this.detailData = res.data.data.data
          this.loading = false
        })
        .catch(() => {
          this.$message.error(`操作错误`)
        })
    },
  },
  mounted() {
    this.getData()
  },
}
</script>
<style lang="less" scoped>
@import "../../../../css/manageMain.less";
</style>
<style lang="less" scoped>
.detail-main {
  background-color: #fff;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  .detail-table {
    width: 100%;
    flex: 1;
    padding: 0 15px;
    overflow: auto;
  }
  .iconfont-svg-main,
  .element-icon {
    width: 27px;
    height: 24px;
  }
  :deep(.cell) {
    display: flex;
     .template-name {
      display: flex;
        > span {
          padding-left: 5px;
        }
    }
  }
}
</style>
