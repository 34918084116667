<template>
  <div class="menu-edit-main">
    <el-drawer
      title="修改分类"
      :visible.sync="drawers"
      :before-close="handleClose"
      >
      <div class="form-class">
        <el-form
          label-width="80px"
          :model="form"
          label-position="top"
          class="add-form"
          :rules="rules"
          ref="form">
          <el-form-item label="上级分类">
            <el-select v-model="form.parent_name" placeholder="请选择上级分类" class='form-input'>
              <el-option label="区域一" value="0" class="form-option">
                <el-tree
                ref="tree" 
                :data="parentData" 
                default-expand-all 
                node-key="id" 
                @node-click="handleNodeClick"
                :props="defaultProps"/>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分类名称" prop='name'>
            <el-input class='form-input' v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="图标">
            <el-select v-model="form.icon" class='form-select'>
              <template slot="prefix">
                <svg aria-hidden="true" class="iconfont-svg">
                   <use :xlink:href="'#' + form.icon" />
                </svg>
              </template>
              <el-option v-for="(icon, index) in iconList" class='icon-select' :key="index" :value="icon">
                <svg aria-hidden="true" class="iconfont-svg">
                   <use :xlink:href="'#' + icon" />
                </svg>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序" prop='sort'>
            <el-input class='form-input' v-model="form.sort"></el-input>
          </el-form-item>
          <el-form-item class="bottom-btn">
            <el-button size="mini" @click="handleClose">取消</el-button>
            <el-button size="mini" type="primary" @click='save'>保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { Tree } from 'element-ui';
import {iconList} from './icon.js'
import { editTemplate, getTemplate } from '@/api/saasManage'
import { deepClone } from '@topology/core';
export default {
  name:'EditDrawer',
  components: {
    'el-tree': Tree
  },
  props: {
    drawer:{
      type:Boolean,
      default:false
    },
    editData:{
      type:Object,
      default:() => {}
    }
  },
  data() {
    return {
      iconList,
      typeData:null,
      drawers:false,
      form:{},
      parentData:[{
        name:'顶级分类',
        id:0,
        children:[]
      }],
      defaultProps: {
        children: "children",
        label: "name",
      },
      rules:{
      }
    };
  },
  watch: {
    drawer(newVal){
      this.drawers = newVal;
    },
    editData(newVal){
      if(newVal && newVal.id){
         this.form = deepClone(newVal);
      }
    }
  },
  methods: {
    save(){
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          return false;
        }
        editTemplate(this.form, this.form.id).then(res=>{
          if(res.data && res.data.code !== 200){
            return false;
          }
          this.$emit('drawerSave');
        }).catch(() => {
          this.$message.error(`操作错误`);
        });
      })

    },
    /**
     * @desc:关闭
     */
    handleClose() {
      this.drawers = false;
      this.$emit('drawerClose');
    },
    /**
     * @desc: 树点击
     */
    handleNodeClick(row){
      this.form.parent_id = row.id;
      this.form.parent_name = row.name;
    },
    getParentData(){
      getTemplate().then(res=>{
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.parentData[0].children = res.data.data.data;
      }).catch(() => {
        this.$message.error(`操作错误`);
      });
    },
  },
  mounted() {
    this.getParentData();
  }
};
</script>
<style lang="less" scoped>
.icon-select{
  display:inline-block;
  width:40px;
  padding: 0 5px;
}
.menu-edit-main{
  color: #444;
  text-align:left;
  .form-class{
    padding: 0px 24px 50px 24px;
    font-size: 14px;
    line-height: 1.5;
    .form-option{
      height: auto;
      background-color: #fff;
    }
    .form-select{
      width:100%;
    }
    .icon-color{
      color: #2f54eb;
    }
  }
  .el-form-item__label{
    padding: 0 0 8px !important;
    width:100% !important;
    text-align: left;
  }
  .bottom-btn{
    margin:0;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    right: 0;
    background: #fff;
    border-radius: 0 0 4px 4px;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
  }

}
.iconfont-svg, .element-icon{
  width: 28px;
  height: 34px;
  font-size: 16px;
  line-height: 42px;
}
</style>