<template>
  <div class="menu-dialog">
    <el-dialog :fullscreen="dialogFull" :visible.sync='dialogs' width="800px" :before-close="onClose">
      <template slot="title">
        <div class="avue-crud__dialog__header">
            <span>
              添加分类
            </span>
          <div class="avue-crud__dialog__menu" @click="dialogFull? dialogFull=false: dialogFull=true">
            <i class="el-icon-full-screen"></i>
          </div>
        </div>
      </template>
      <el-form
        label-width="80px"
        :model="form"
        label-position="top"
        class="add-form"
        :rules="rules"
        ref="dialog">
        <el-form-item label="上级分类">
          <el-select v-model="form.parent_name" placeholder="请选择上级分类" class='form-input'>
            <el-option label="区域一" value="0" class="form-option">
              <el-tree
              ref="tree"
              :data="parentData"
              default-expand-all
              node-key="id"
              @node-click="handleNodeClick"
              :props="defaultProps"/>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类名称" prop='name'>
          <el-input class='form-input' v-model="form.name"></el-input>
        </el-form-item>
          <el-form-item label="图标" v-if="form.type!=='3'">
            <el-select v-model="form.icon" class='form-input'>
              <template slot="prefix">
                <svg aria-hidden="true" class="iconfont-svg">
                   <use :xlink:href="'#' + form.icon" />
                </svg>
              </template>
              <el-option v-for="(icon, index) in iconList" class='icon-select' :key="index" :value="icon">
                <svg aria-hidden="true" class="iconfont-svg">
                   <use :xlink:href="'#' + icon" />
                </svg>
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="排序" prop='sort'>
          <el-input class='form-input' v-model="form.sort"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="mini" @click="onClose">取消</el-button>
        <el-button size="mini" type="primary" @click="onSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Tree } from 'element-ui';
import { iconList } from './icon.js'
import { addTemplate, getTemplate } from '@/api/saasManage'
export default {
  name: 'AddDialog',
  components: {
    'el-tree': Tree,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    parentParam: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      iconList,
      typeData: null,
      parentData: [{
        name: '顶级分类',
        id: 0,
        children: []
      }],
      dialogs: false,
      dialogFull: false,
      form: {
        parent_name: '',//父级名称
        name: '',//分类名称
        parent_id: 0,//父级id
        icon:''
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
      rules: {
      }
    };
  },
  watch: {
    dialog(newVal) {
      this.dialogs = newVal;
      if (newVal) {
        this.getParentData();
      }
    },
    parentParam(newVal) {
      this.form.parent_id = newVal.parent_id;
      this.form.parent_name = newVal.parent_name;
    }
  },

  methods: {
    /**
     * @desc 保存按钮
     */
    onSave() {
      this.$refs['dialog'].validate((valid) => {
        if (valid) {
          addTemplate(this.form).then(res => {
            if(res.data && res.data.code !== 200){
              return false;
            }
            this.$emit('dialogSave');
            this.form = {
              parent_name: '',//父级名称
              name: '',//分类名称
              parent_id: 0,//父级id
              icon:''
            };
          }).catch(() => {
            this.$message.error(`操作错误`);
          });
        } else {
          return false;
        }
      });
    },
    /**
     * @desc:关闭按钮
     */
    onClose() {
      this.dialogs = false;
      this.form = {
          parent_name: '',//父级名称
          name: '',//分类名称
          parent_id: 0,//父级id
      };
      this.$emit('dialogClose');
    },
    /**
     * @desc:获取上级分类下拉选择框的数据
     * @param
     */
    getParentData() {
      getTemplate().then(res => {
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.parentData[0].children = res.data.data.data;
      }).catch(() => {
        this.$message.error(`操作错误`)
      });
    },
    /**
     * @desc: 下拉树选择的数据
     */
    handleNodeClick(row) {
      this.form.parent_id = row.id;
      this.form.parent_name = row.name;
    }
  },
  mounted() {
    this.getParentData();
  }
};
</script>
<style lang="less" scoped>
.form-option {
  height: auto;
  background-color: #fff !important;
}




:deep(.el-select-dropdown) {
  // 控制图标下拉选择的宽度  不然会超出宽度
  width: 100px !important;

}
  .icon-select {
  display: inline-block;
  padding: 0px 8px;
  margin-top: 5px;
}

.menu-dialog {
  .add-form {
    height: 350px;
    overflow: auto;
  }
}
.iconfont-svg, .element-icon{
  width: 30px;
  height: 32px;
  font-size: 16px;
  line-height: 42px;
}
</style>

<style lang="less" scoped>
@import "../../../../css/manageAdd.less";
</style>
